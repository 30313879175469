@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Typography -------------------------------------------------------------*/
    --font-family-sans: "Work Sans", "ui-sans-serif", "system-ui",
      "-apple-system";

    /* Colors -----------------------------------------------------------------*/
    --blue-dark: #0f1d29;
    --blue: #0a6abf;
    --blue-light: #18ccfc;
    --purple-dark: #6344f5;
    --purple-light: #ae48ff;

    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    /* Destructive red text color, slightly lighter than --destructive */
    --destructive-text: 0 84.2% 20.2%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    /* Destructive red text color, slightly lighter than --destructive */
    --destructive-text: 0 84.2% 50%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer utilities {
  .mask-image-gradient {
    mask-image: linear-gradient(
      to right,
      transparent,
      black 80px,
      black 80%,
      transparent
    );
  }

  @keyframes slideLeft {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }

  @keyframes slideRight {
    0% {
      right: 0;
    }
    100% {
      right: -100%;
    }
  }

  .animate-slideLeft {
    animation: slideLeft 50s linear infinite;
  }

  .animate-slideRight {
    animation: slideRight 50s linear infinite;
  }
}
